import React, { Fragment, useCallback, useContext } from 'react';
import { globalContext } from "@src/redux/reducer";
import { actions } from '@src/redux/actions';
import { Link, useParams } from 'react-router-dom';
import './index.scss';

export default function CategoryMenu({ style = {}, closeModal }) {
    const { store: { categorySelectedData, categoryData }, dispatch } = useContext(globalContext);
    let params = useParams();

    const expandFunc = useCallback((level, index) => {
        let str = `${level}-${index}`;
        let arr = [...categorySelectedData];
        arr = arr.filter(item => !item.includes(`${level}-`));
        let res = null;
        if (arr.includes(str)) {
            res = arr.filter(item => item !== str);
        } else {
            res = [...arr, str];
        }
        dispatch(actions.categorySelectedData(res));
    }, [categorySelectedData]);

    const closeModalAction = useCallback(() => {
        closeModal && closeModal();
    }, [closeModal]);

    return (
        <div style={{ ...style }} className="category-menu">
            <ul className="categoryList">
                {
                    categoryData && categoryData.map((item, index) => {
                        return (
                            <li key={item.guid} className={`${params.id === item.guid ? 'active' : null}`}>
                                <Link onClick={closeModalAction}
                                    to={`/category/${item.guid}`}>{item.name}</Link>
                                {
                                    item.children && <Fragment>
                                        {
                                            categorySelectedData.includes(`2-${index}`) ?
                                                <span className="close" onClick={() => expandFunc(2, index)}>-</span> :
                                                <span className="expand" onClick={() => expandFunc(2, index)}>+</span>
                                        }
                                    </Fragment>
                                }
                                {
                                    (item.children || null) &&
                                    <ul className="secondUl" style={{ display: categorySelectedData.includes(`2-${index}`) ? "block" : "none" }}>
                                        {
                                            item.children.map((_item, _index) => {
                                                return (
                                                    <li key={_item.guid} className={`${params.id === _item.guid ? 'active' : null}`}>
                                                        <Link onClick={closeModalAction}
                                                            to={`/category/${_item.guid}`}>{_item.name}</Link>
                                                        {
                                                            _item.children && <Fragment>
                                                                {
                                                                    categorySelectedData.includes(`3-${_index}`) ?
                                                                        <span className="close" onClick={() => expandFunc(3, _index)}>-</span> :
                                                                        <span className="expand" onClick={() => expandFunc(3, _index)}>+</span>
                                                                }
                                                            </Fragment>
                                                        }
                                                        {
                                                            (_item.children || null) &&
                                                            <ul className="thirdUl" style={{ display: categorySelectedData.includes(`3-${_index}`) ? "block" : "none" }}>
                                                                {
                                                                    _item.children.map(it => {
                                                                        return (
                                                                            <li key={it.guid} className={`${params.id === it.guid ? 'active' : null}`}>
                                                                                <Link onClick={closeModalAction}
                                                                                    to={`/category/${it.guid}`}>{it.name}</Link>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
