export default {
    aboutUs: '关于我们',
    allGoods: '全部商品',
    loadMore: '加载更多',
    reachBottom: '没有更多啦',
    goodsKinds: '产品分类',
    name: '名称',
    price: '价格',
    stock: '库存',
    operate: '操作',
    edit: '编辑',
    delete: '删除',
    sure: '确认',
    save: '保存',
    cancel: '取消',
    time: '时间',
    status: '状态',
    look: '查看',
    pics: '图片',
    discount: '折扣',
    number: '数量',
    total: '总价',
    search: '搜索',
    loading: '加载中',

    checkout1: "信用卡支付",
    checkout2: "货到付款",
    checkout3: "线下支付",
    checkout4: "请选择地址后下单",
    checkout5: "下单",
    checkout6: "新增地址",
    checkout7: "订单信息",
    checkout8: "产品",
    checkout9: "小计",
    checkout10: "折扣金额",
    checkout11: "总金额",
    checkout12: "订单留言",
    checkout13: "支付方式",
    checkout14: "确认下单",

    collect1: "我的收藏",
    collect2: "添加到购物车",
    collect3: "取消收藏",
    collect4: "收藏",
    collect5: "添加到我的收藏",
    collect6: "添加购物车",

    goods1: "商品介绍",
    goods2: "相关产品",

    index1: "推荐的商品",
    index2: "新品推荐",
    index3: "折扣商品",

    personal1: "邮箱地址",
    personal2: "密码变更",
    personal3: "当前密码",
    personal4: "新密码",
    personal5: "保留空白以保持不变",
    personal6: "确认新密码",
    personal7: "保存更改",
    personal8: "个人中心",
    personal9: "订单列表",
    persona20: "地址列表",
    persona21: "账户详情",
    persona22: "退出登录",

    address1: "新增收货地址",
    address2: "收货地址",
    address3: "联系人姓名",
    address4: "联系人电话",
    address5: "国家",
    address6: "省份",
    address7: "城市",
    address8: "邮编",
    address9: "详细地址",
    address10: "设为默认地址",
    address11: "是否确认删除地址？",

    order1: "尚无订单",
    order2: "浏览产品",
    order3: "订单号",
    order4: "订单",
    order5: "订单详情",
    order6: "下单时间",
    order7: "订单状态",
    order8: "金额",
    order9: "运费",

    shpCar1: "购物车",
    shpCar2: "购物车空空如也，快去",
    shpCar3: "挑选商品",
    shpCar4: "吧",
    shpCar5: "合计",
    shpCar6: "查看购物车",

    user1: "请输入邮箱和密码",
    user2: "邮箱格式不正确",
    user3: "请输入账号和密码",
    user4: "用户登录",
    user5: "登录",
    user6: "用户名或者邮箱地址",
    user7: "密码",
    user8: "记住我",
    user9: "注册",
    user10: "邮箱地址",
    user11: "注册成功，请登录",
    user12: "忘记密码",
    user14: "返回登录",
    user15: "忘记密码",
    user16: "确认退出登录吗？",

    request1: "接口响应超时",
    request2: "用户无权限",
    request3: "调用接口出错",
    request4: "你还没有登陆，请登陆",

    common1: "关于我们",
    common2: "联系我们",
    common3: "尾页",
    common4: "跳转",
    common5: "输入合法数字",
    common6: "返回",

    nav1: "首页",
    nav2: "分类",
    nav3: "按分类查找商品",
    nav4: "输入关键字",
    nav5: "前往登录",

    add1: "配送方式",
    add2: "邮寄到家",
    add3: "上门自取",
    add4: "超出配送范围",
    add5: "我们已经向您的邮箱发送了一个链接！请到您的邮箱验证后登录",
    add7: "配置我的Cookie",
    add8: "在我们的网站上，我们使用cookie来确保网站的正常运行，以根据您的喜好，其内容和我们在第三方网站上的广告进行个性化并进行统计分析。",
    add9: "接受",
    add10: "帮助中心",
    add11: "注册协议",
    add12: "公司名称",
    add13: "电话",
    add14: "城市国家",
    add15: "地址",
    add16: "同城配送",
    add17: "请选择支付方式",
    add18: "货到付款",
    add19: "请登录后操作",

    "ximiadd264": "英镑",
    "ximiadd265": "元",
    "ximiadd266": "欧元",
    "ximiadd267": "美元",
    "ximiadd268": "第纳尔",
    "ximiadd269": "网站技术由西米提供",
    "待支付": "待支付",
    add20: "提示",
    add21: "确定",
    "邮箱已注册": "邮箱已注册",
    add22: "忘记密码",
    add23: "请输入你的邮箱，您将收到一个链接来帮助你修改密码",
    add24: "修改密码",
    add25: "请输入新密码",
    add26: "请确认新密码",
    add27: "两次输入的密码不一致",
    add28: "邮件已发送到您的邮箱",
    add29: "密码修改成功，点击跳转登录",
    "已支付": "已支付",
    "已取消": "已取消",
    "ximiadd318": "Boliviano",
    "ximiadd319": "确认支付",
    "ximiadd320": "继续支付",
    "ximiadd323": "中文",
    "ximiadd322": "English",
    "ximiadd321": "Español",
    "ximiadd324": "1111",
    "ximiadd325": "1111",
    "ximiadd326": "1111",
    "ximiadd327": "1111",
    "ximiadd328": "1111",
    "ximiadd329": "1111",
    "ximiadd330": "1111",
}
