export default {
    aboutUs: 'Sobre nosotros',
    allGoods: 'Todos los productos',
    loadMore: 'Mas',
    reachBottom: 'No hay mas',
    goodsKinds: 'Categorías',
    name: 'Nombre',
    price: 'Precio',
    enstock: 'en stock',
    operate: 'Operar',
    edit: 'Editar ',
    delete: 'Eliminar',
    sure: 'Aceptar',
    save: 'Guardar',
    cancel: 'Cancelar',
    time: 'Tiempo',
    status: 'Estado',
    look: 'Ver',
    pics: 'Foto',
    discount: 'Descuento',
    number: 'Cantidad',
    total: 'Total',
    search: 'Buscar',
    loading: 'Cargando',

    checkout1: "Pago con tarjeta",
    checkout2: "Contrareembolso",
    checkout3: "Pago en efectivo",
    checkout4: "Seleccionar dirección",
    checkout5: "Pedir",
    checkout6: "Añadir nueva dirección",
    checkout7: "Información del pedido",
    checkout8: "Productos",
    checkout9: "Subtotal",
    checkout10: "Descuento",
    checkout11: "Total",
    checkout12: "Mensaje adicional",
    checkout13: "Método de pago",
    checkout14: "Seguir",

    collect1: "Mis favoritos",
    collect2: "Añadir al carrito",
    collect3: "Cancelar favoritos",
    collect4: "Favoritos",
    collect5: "Añadir a mis favoritos",
    collect6: "Añadir al carrito",

    goods1: "Descripción",
    goods2: "Relacionada",

    index1: "Recomendar",
    index2: "Nuevo",
    index3: "Descuento",

    personal1: "Correo electrónico",
    personal2: "Cambiar contraseña",
    personal3: "Contraseña actual",
    personal4: "Nueva contraseña",
    personal5: "Déjelo en blanco para mantenerlo sin cambios",
    personal6: "Repetir nueva contraseña",
    personal7: "Guardar",
    personal8: "Datos personales",
    personal9: "Pedido",
    persona20: "Dirección",
    persona21: "Cuenta",
    persona22: "Cerrar sesión",

    address1: "Añadir nueva dirección",
    address2: "Dirección de envio",
    address3: "Nombre y apellido",
    address4: "Telefono",
    address5: "Pais",
    address6: "Provincia",
    address7: "Ciudad",
    address8: "Código Postal",
    address9: "Calle, Numero",
    address10: "Dirección principal",
    address11: "¿Quieres eliminar la dirección？",

    order1: "No hay pedido",
    order2: "Buscar productos",
    order3: "Numero del pedido",
    order4: "Pedido",
    order5: "Información del pedido",
    order6: "Fecha del pedido",
    order7: "Estado del pedido",
    order8: "Precio",
    order9: "Envio",

    shpCar1: "Carrito",
    shpCar2: "Carrito está vacío",
    shpCar3: "Elegir productos",
    shpCar4: "",
    shpCar5: "Total",
    shpCar6: "Carrito",

    user1: " Introduce correo electrónico y contraseña",
    user2: "Correo electrónico incorrecto",
    user3: "Introduce cuenta y contraseña",
    user4: "Iniciar sesión ",
    user5: "Iniciar sesión",
    user6: "Usuario o correo electrónico",
    user7: "Contraseña",
    user8: "Recuérdame",
    user9: "Regístrate",
    user10: "Correo electrónico",
    user11: "Correcto,ya puedes iniciar sesión",
    user12: "¿Has olvidado tu contraseña?",
    user14: "Volver al inicio sesión",
    user15: "¿Has olvidado tu contraseña?",
    user16: "¿Quieres cerrar sesión?",

    request1: "Error",
    request2: "Sin autorización",
    request3: "Error",
    request4: "Por favor inicia sesión",

    common1: "Sobre nosotros",
    common2: "Atención al cliente",
    common3: "Última página",
    common4: "Saltar",
    common5: "Introduce el número correcto ",
    common6: "Volver",

    nav1: "Inicio",
    nav2: "Categorías",
    nav3: "Buscar",
    nav4: "Buscar",
    nav5: "Iniciar sesión",

    add1: "Modo de distribución",
    add2: "Envío a domicilio",
    add3: "Para recoger",
    add4: "Fuera del alcance de la distribución",
    add5: "¡Hemos enviado un enlace como su buzón de correo!Por favor, verifique su buzón de correo",
    add7: "CONFIGURAR MIS COOKIES",
    add8: "En nuestro sitio web, utilizamos cookies destinadas a asegurar el buen funcionamiento del sitio para personalizar, según sus preferencias, su contenido y nuestra publicidad en los sitios de terceros y para hacer análisis estadísticos.",
    add9: "Aceptar las cookies",
    add10: "Centro de ayuda",
    add11: "Acuerdo de registro",
    add12: "Nombre de la empresa",
    add13: "Teléfono",
    add14: "Ciudades  Pais",
    add15: "Dirección",
    add16: "Entrega a domicilio",
    add17: "Por favor, seleccione el modo de pago",
    add18: "Contra reembolso",
    add19: "Inicie sesión después de la operación",

    "ximiadd264": "Libra esterlina",
    "ximiadd265": "Yuan",
    "ximiadd266": "Euros",
    "ximiadd267": "Dólares",
    "ximiadd268": "Diinar",
    "ximiadd269": "Tecnología del sitio web proporcionada por XIMI",
    "待支付": "No pagado",
    add20: "Prompt",
    add21: "Confirmar",
    "邮箱已注册": "El correo electrónico ha sido registrado, por favor inicie sesión",
    add22: "Recuperar la contraseña",
    add23: "Correo electrónico registrado",
    add24: "Recuperar la contraseña",
    add25: "Nueva contraseña",
    add26: "repite la nueva contraseña",
    add27: "Las dos contraseñas ingresadas no coinciden.",
    add28: "El contenido ha sido enviado a su correo electrónico.",
    add29: "Contraseña cambiada con éxito, haga clic para iniciar sesión",
    "已支付": "Pagado",
    "已取消": "Cancelado",
    "ximiadd318": "Boliviano",
    "ximiadd319": "Confirmación del pago",
    "ximiadd320": "Seguir pagando",
    "ximiadd323": "中文",
    "ximiadd322": "English",
    "ximiadd321": "Español",
}
