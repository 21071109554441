import React, {useState, useContext, useEffect, useCallback, useRef} from 'react';
import Nav from './Nav';
import CarModal from './CarModal';
import {globalContext} from '@src/redux/reducer';
import { useHistory, Link } from 'react-router-dom';
import useShopCarApi from '@src/api/useShopCarApi';
import useCollect from '@src/api/useCollect';
import Tr from '@src/translate';
import { actions } from '@src/redux/actions';
import './index.scss';

const lanObj = {
    SPANISH: 'Español',
    EN: 'English',
    ZH: '中文',
}

export default function Header() {
    const [showSearch, setShowSearch] = useState(false);
    const [languageVisible, setLanguageVisible] = useState(false);
    const [queryName, setQueryName] = useState('');
    const [showCarModal, setShowCarModal] = useState(false);
    const { store, dispatch } = useContext(globalContext);
    const history = useHistory();
    const { getList: getShopCarList, getTotal, editCart, loading, total } = useShopCarApi();
    const { getList: getCollectList } = useCollect();
    const headDom = useRef(null);

    const goToCollect = useCallback(()=>{
        if(store.userInfo){
            history.push("/collect");
        }else{
            history.push("/login");
        }
    }, [history, store]);

    const openCarModalAction = useCallback((e)=>{
        location.hash = '/shopcar';
        // setShowCarModal(!showCarModal);
        // if(store.userInfo){
        // }else{
        //     alert(Tr('add19'));
        //     history.push("/login");
        // }
        // e.nativeEvent.stopImmediatePropagation();
    }, [history, store, showCarModal]);

    const selectLanguage = (e, lang)=>{
        dispatch(actions.language(lang, true));
        setLanguageVisible(false);
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
    }

    useEffect(()=>{
        if(store.userInfo){
            getCollectList();
        }
        getShopCarList();
    }, [store.userInfo]);

    useEffect(()=>{
        if(store.userInfo){
            getTotal(store.shopCarList);
        }
    }, [store.shopCarList, store.userInfo]);

    useEffect(()=>{
        let closeModal = ()=>{
            setTimeout(()=>{
                setLanguageVisible(false);
            }, 200)
        }
        document.addEventListener('click', closeModal);
        return ()=>{
            document.removeEventListener('click', closeModal);
        }
    }, [])

    let languageType = store.languageType;

    return (
        <div id="header" className={"header " + ( window.themeType === 2 ? 'theme-type2' : 'theme-type1' )} ref={ headDom }>
            <div className="header-layout-top-container">
                <div className="layout header-layout-top">
                    <div className="header-top-left">
                        <Link to={'/'}>
                            <img className="center" src={ getLogo(store.storeInfo && store.storeInfo.images || []) } alt=""/>
                        </Link>
                    </div>
                    <div className="header-right">
                        <div className="center search-container"
                             style={{...(showSearch ? {display: 'inline-block'} : {})}}>
                            <input onChange={ e=>setQueryName(e.target.value) }
                                   onBlur={ e=>setShowSearch(false) }
                                   placeholder={ Tr("nav4") }/>
                            <button onClick={ ()=>history.push(`/goods/${queryName}`) }>{ Tr("search") }</button>
                        </div>
                        <div className="center icons-container">
                            <div className="icons">
                                <div className="icon icon-personal"
                                     onClick={(e) => {
                                         if(store.userInfo){
                                             history.push('/personal/order');
                                         }else{
                                             history.push('/login');
                                         }
                                     }}> </div>
                            </div>
                            <div className="icons with-number">
                                <div className="icon icon-collect" onClick={ goToCollect }> </div>
                                <div className="number">{ store.collectList && store.collectList.length || 0 }</div>
                            </div>
                            <div className="icons with-number">
                                <div className="icon icon-car" onClick={ openCarModalAction }> </div>
                                <div className="number">{ getShopNumbers(store.shopCarList) }</div>
                                <CarModal closeModal={() => setShowCarModal(false)}
                                          list={ store.shopCarList || [] }
                                          editCart={ editCart }
                                          loading={ loading }
                                          total={ total }
                                          userInfo={ store.userInfo }
                                          style={{display: showCarModal ? 'block' : 'none'}}/>
                            </div>
                            {
                                showSearch ?
                                    <div className="icons">
                                        <div onClick={() => {
                                            setShowSearch(false)
                                        }} className="icon icon-close"> </div>
                                    </div> :
                                    <div className="icons">
                                        <div onClick={() => {
                                            setShowSearch(true)
                                        }} className="icon icon-search"> </div>
                                    </div>
                            }
                            {
                                languageType === 2 ?
                                    <div className="toogle-language icons">
                                        <div style={{width: 25, height: 25}} className="text" onClick={(e)=>{
                                            if(!languageVisible){
                                                e.nativeEvent.stopImmediatePropagation();
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }
                                            setLanguageVisible(!languageVisible);
                                        }}>
                                            <img style={{width: 25, height: 25}} src="./public/img/language-outline.svg" alt=""/>
                                        </div>
                                        {
                                            languageVisible?
                                                <div className="language-list">
                                                    <ul>
                                                        <li onClick={(e)=>selectLanguage(e,'ZH')}>中文</li>
                                                        <li onClick={(e)=>selectLanguage(e,'EN')}>English</li>
                                                        <li onClick={(e)=>selectLanguage(e,'SPANISH')}>Español</li>
                                                    </ul>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Nav/>

            <div className={ "mobile-bottom-nav bottomBar " + ( window.themeType === 2 ? 'theme-type2' : 'theme-type1' ) }>
                {/* <Nav/> */}
                <div className="icons">
                    <div className="icon icon-index"
                         onClick={(e) => {
                             history.push('/index');
                         }}> </div>
                </div>
                <div className="icons">
                    <div className="icon icon-category"
                         onClick={(e) => {
                             history.push('/category_list');
                         }}> </div>
                </div>

                <div className="icons with-number">
                    <div className="icon icon-collect" onClick={ goToCollect }> </div>
                    <div className="number">{ store.collectList && store.collectList.length || 0 }</div>
                </div>
                <div className="icons with-number">
                    <div className="icon icon-car" onClick={ openCarModalAction }> </div>
                    <div className="number">{ getShopNumbers(store.shopCarList) }</div>
                    <CarModal closeModal={() => setShowCarModal(false)}
                              list={ store.shopCarList || [] }
                              editCart={ editCart }
                              loading={ loading }
                              total={ total }
                              userInfo={ store.userInfo }
                              style={{display: showCarModal ? 'block' : 'none'}}/>
                </div>
                {

                    languageType === 2 ?
                        <div className="toogle-language icons">
                            <div style={{width: 25, height: 25}} className="text" onClick={(e)=>{
                                if(!languageVisible){
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.stopPropagation();
                                    e.preventDefault();
                                }
                                setLanguageVisible(!languageVisible);
                            }}>
                                <img style={{width: 25, height: 25}} src="./public/img/language-outline.svg" alt=""/>
                            </div>
                            {
                                languageVisible?
                                    <div className="language-list" style={{top: 'auto', bottom: '100%'}}>
                                        <ul>
                                            <li onClick={(e)=>selectLanguage(e,'ZH')}>中文</li>
                                            <li onClick={(e)=>selectLanguage(e,'EN')}>English</li>
                                            <li onClick={(e)=>selectLanguage(e,'SPANISH')}>Español</li>
                                        </ul>
                                    </div>
                                    : null
                            }
                        </div>
                        : null
                }
                <div className="icons">
                    <div className="icon icon-personal"
                         onClick={(e) => {
                             if(store.userInfo){
                                 history.push('/personal/order');
                             }else{
                                 history.push('/login');
                             }
                         }}> </div>
                </div>
                {
                    showSearch ?
                        <div className="icons search-icons">
                            <div onClick={() => {
                                setShowSearch(false)
                            }} className="icon icon-close"> </div>
                        </div> :
                        <div className="icons search-icons">
                            <div onClick={() => {
                                setShowSearch(true)
                            }} className="icon icon-search"> </div>
                        </div>
                }
            </div>
        </div>
    )
}

function getLogo(list=[]){
    let item = list.find(it=>it.imageTypeStr === 'Logo');
    return item && item.url;
}

function getShopNumbers(list){
    if(!list || !list.length) return 0;
    return list.reduce((total = 0, item)=>{
        return total + item.quantity;
    }, 0)
}