export default function confirmModal({ title, content, okFun, cancelFun, okText, cancelText, noCancelBtn, showTitle }){
    let con = document.createElement('div');
    con.className = 'confirm_modal_container_shadow';
    let str = `<div class="confirm_modal_container"><div class="confirm_title">${ showTitle?title:'' }</div><div class="confirm_content">${content}</div><div class="confirm_footer"></div></div>`;
    con.innerHTML = str;
    document.body.appendChild(con);

    let okBtn = document.createElement('div');
    let cancelBtn = document.createElement('div');
    okBtn.className = 'okBtn';
    okBtn.innerHTML = okText;
    cancelBtn.className = 'cancelBtn';
    cancelBtn.innerHTML = cancelText;

    const closeModal = ()=>{
        con.parentNode.removeChild(con);
    }
    okBtn.onclick = function (){
        okFun && okFun();
        closeModal();
    }
    cancelBtn.onclick = function (){
        cancelFun && cancelFun();
        closeModal();
    }
    let footer = con.querySelector('.confirm_footer');
    if(!noCancelBtn) footer.appendChild(cancelBtn);
    footer.appendChild(okBtn);
}