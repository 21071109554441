import Tr from '@src/translate';
import confirmModal from "@src/util/confirm";
/**
 * fetch发起请求
 * @param options  请求配置
 *        {
 *          method: string 请求类型
 *          url: string ...
 *          data: obj | string 请求数据
 *          static: bool 为true则不将data JSON.stringify 否则...
 *          headers: obj 自定义header头
 *          hideLoadingText: bool 是否隐藏加载提示
 *          loadingText: str 加载时的文字提示
 *          blobData: bool  返回是否为二进制数据
 *          noAuth: bool   无用户验证头
 *        }
 * @param success   成功回调
 * @param error     错误回调
 */

export default function Req(options, success, error){
    let useInfo = JSON.parse(localStorage.getItem("USERINFO"));
    let tokenContent = useInfo && useInfo.tokenContent;
    let request = {
        method: options.type,
        headers: {
            "Authorization": tokenContent ? 'Bearer ' + tokenContent : '',
            "Content-Type": "application/json",
        }
        // credentials: 'include'
    };
    if(options.headers){
        for(let key in options.headers){
            request.headers[key] = options.headers[key];
        }
    }

    if(options.data && options.static){
        request.body = options.data;
        delete request.headers['Content-Type'];
    }else{
        request.body = JSON.stringify(options.data)
    }

    let params = '';
    //get请求加上时间戳
    if(!window.storeGuid || location.href.includes("localhost")){
        window.storeGuid = "412ab262f6b147a481b34b3a974df7e2"
        // window.storeGuid = "94683127b88e4efe854f6e0b8c1a6ac7"
    }
    let query = `time=${ new Date().getTime() }&storeGuid=${window.storeGuid}`;
    if(options.url.includes('/customer/v1/store/paymethod')) query += `&guid=${window.storeGuid}`;
    params = (options.url.indexOf('?') === -1 ? '?' : '&') + query;

    //对url参数进行编码 兼容IE传中文参数
    let url = window.serverUrl + options.url;
    if(url.indexOf('?') !== -1){
        let index = url.indexOf('?');
        let queryParams = url.slice(index + 1);
        url = url.slice(0, index + 1);
        url += encodeURI(queryParams);
    }

    let hide = null;

    return  Promise.race([
        fetch(url + params, request),
        new Promise((resolve, reject)=>{
            setTimeout(()=>{
                reject(new Error(Tr('request1') + '!'))
            }, 60000)
        })
    ]).then(async (res)=>{
        hide && hide();
        if(res.status === 401 || res.status === 403){
            confirmModal({
                title: '',
                content: Tr('request4') + "!",
                okText: Tr("add21"),
                noCancelBtn: true,
                okFun: ()=>{
                    location.hash = '/login';
                }
            });
            return ;
        }
        if(options.blobData){
            return res.blob();
        }
        let data = await res.json();
        if(data.isError){
            success(null);
            if(data.msg === "店铺域名不存在" && location.href.includes('localhost')) return ;
            if(url.includes('/v1/customer/login') && data.msg === '该账号邮箱尚未验证,无法正常登录'){
                confirmModal({
                    title: '',
                    content: Tr("add5"),
                    okText: Tr("add21"),
                    noCancelBtn: true,
                });
            }else{
                if(data.msg === "Token not provided"){
                    confirmModal({
                        title: '',
                        content: Tr("add19"),
                        okText: Tr("add21"),
                        noCancelBtn: true,
                    });
                }else{
                    confirmModal({
                        title: '',
                        content: Tr(data.msg) ||  (Tr('request3') + '!'),
                        okText: Tr("add21"),
                        noCancelBtn: true,
                    });
                }
            }
        }else{
            if(success && typeof success === 'function'){
                success && success(data);
            }
        }
        return data;
    }).catch(error=>{
        hide && hide();
        if(error && typeof error === 'function'){
            error && error(error);
        }
        console.log(error);
    });
}
