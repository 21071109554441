// 此处设置的状态只可在同一个组件共享，用于分离页面的接口调用
import React, {useState, useCallback, useEffect, useContext} from 'react';
import Req from "@src/util/request";
import {globalContext} from "@src/redux/reducer";
import {actions} from "@src/redux/actions";

export default function useShopCarApi(){
    const [list, setList] = useState(null);
    const [total, setTotal] = useState({});
    const [loading, setLoading] = useState(false);
    const { store, dispatch } = useContext(globalContext);

    const getList = useCallback(()=>{
        if(!isLogin()){
            dispatch(actions.shopCarList(JSON.parse(localStorage.getItem('localCartGoods')) || []));
            setList(JSON.parse(localStorage.getItem('localCartGoods')) || []);
            return ;
        }
        setLoading('list');
        Req({
            url: '/customer/v1/order/cartList',
            type: "POST"
        }, res=>{
            if(res){
                dispatch(actions.shopCarList(res.data));
                setList(res.data);
            }
            setLoading(false);
        }, error=>{
            setLoading(false);
        });
    }, []);

    const getTotal = useCallback((items, paymentType)=>{
        let _list = items;
        if(!items){
            _list = list;
        }
        if(!_list || !_list.length) return ;
        setLoading('total');
        Req({
            url: '/customer/v1/order/calculate',
            type: "POST",
            data: {
                payment_method_id: paymentType,
                items: (_list.filter(it=>it.quantity)).map(item=>{
                    return {
                        quantity: item.quantity,
                        commodityGuid: item.commodity && item.commodity.guid || item.commodity_guid
                    }
                })
            }
        }, res=>{
            if(res){
                setTotal(res.data);
            }
            setLoading(false);
        }, error=>{
            setLoading(false);
        });
    }, [list]);

    const addToLocalCart = useCallback((data, callBack)=>{
        let localCartGoods = JSON.parse(localStorage.getItem('localCartGoods'));
        if(!localCartGoods) localCartGoods = [];
        let existIndex = localCartGoods.findIndex(item=>item.commodity_guid === data.commodity_guid);
        if(existIndex !== -1){
            localCartGoods[existIndex].quantity ++;
        }else{
            localCartGoods.push(data);
        }
        localStorage.setItem('localCartGoods', JSON.stringify(localCartGoods));
        getList();
        callBack && callBack();
    }, [])
    const editLocalCart = useCallback((data)=>{
        let localCartGoods = JSON.parse(localStorage.getItem('localCartGoods'));
        if(!localCartGoods) localCartGoods = [];
        let existIndex = localCartGoods.findIndex(item=>item.commodity_guid === data.commodity_guid);
        if(data.quantity === 0){
            localCartGoods.splice(existIndex, 1);
        }else{
            localCartGoods[existIndex].quantity = data.quantity;
        }
        localStorage.setItem('localCartGoods', JSON.stringify(localCartGoods));
        getList();
    }, [])
    const editCart = useCallback((data)=>{
        if(!isLogin()){
            editLocalCart(data);
            return ;
        }
        setLoading('edit');
        Req({
            url: '/customer/v1/order/editCart',
            type: "POST",
            data
        }, res=>{
            if(res){
                getList();
            }
            setLoading(false);
        }, error=>{
            setLoading(false);
        });
    }, []);
    const addToCart = useCallback((data, callBack)=>{
        if(!isLogin()){
            addToLocalCart(data, callBack);
            return ;
        }
        setLoading('add');
        Req({
            url: '/customer/v1/order/addCart',
            type: "POST",
            data
        }, res=>{
            if(res){
                getList();
                callBack && callBack();
            }
            setLoading(false);
        }, error=>{
            setLoading(false);
        });
    }, []);

    const getGoodsNumber = useCallback((id)=>{
        let _data = store.shopCarList || [];
        let number = 0;
        _data.forEach(item=>{
            if(item.commodity_guid === id){
                number += item.quantity;
            }
        });
        return number;
    }, [store.shopCarList]);

    const clearAll = useCallback(()=>{
        setLoading('clear');
        Req({
            url: '/customer/v1/order/removeCart',
            type: "POST"
        }, res=>{
            if(res){
                getList();
            }
            setLoading(false);
        }, error=>{
            setLoading(false);
        });
    }, []);

    //将未登录时的本地购物车数据添加到远程
    const addLocalToRemote = useCallback(()=>{
        let localCartGoods = JSON.parse(localStorage.getItem('localCartGoods'));
        if(localCartGoods){
            localCartGoods.forEach(item=>{
                addToCart({
                    commodity_guid: item.commodity.guid,
                    price: item.commodity.price,
                    quantity: item.quantity || 1
                }, ()=>{
                    setTimeout(()=>{
                        localStorage.removeItem('localCartGoods');
                    }, 1000)
                })
            });
        }
    }, [])

    return {
        list,
        getList,
        addToCart,
        editCart,
        loading,
        total,
        getTotal,
        getGoodsNumber,
        clearAll,
        addLocalToRemote
    }
}

function isLogin(){
    let useInfo = JSON.parse(localStorage.getItem("USERINFO"));
    return !!(useInfo && useInfo.tokenContent);
}