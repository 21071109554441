import React from 'react';
import {Link} from 'react-router-dom';
import Tr from '@src/translate';
import confirmModal from "@src/util/confirm";
import './index.scss';

export default function CarModal({style = {}, closeModal, list = [], editCart, loading, total, userInfo}) {

    const goToCheckout = ()=>{
        closeModal();
        if(!userInfo){
            // confirmModal({
            //     title: '',
            //     content: Tr("add19"),
            //     okText: Tr("add21"),
            //     noCancelBtn: true,
            // });
            location.hash = '/login';
        }else{
            location.hash = '/checkout';
        }
    }

    return (
        <div className="shop-car-modal" style={{...style}} onClick={ e=>e.nativeEvent.stopImmediatePropagation() }>
            <ul>
                {
                    list.map(item => {
                        return (
                            <li key={item.guid}>
                                <img src={item.commodity && item.commodity.master_img_url} alt=""/>
                                <div className="info">
                                    <div className="title"
                                         title={item.commodity && item.commodity.name}>{item.commodity && item.commodity.name}</div>
                                    <div className="num">{item.quantity} × {item.price}{getCurrency()}</div>
                                </div>
                                <div className={`delete ${ loading && 'loading-status' }`} onClick={() => {
                                    editCart({
                                        commodity_guid: item.commodity && item.commodity.guid || item.commodity_guid,
                                        quantity: 0
                                    })
                                }}>{loading ? '' : '×'}</div>
                            </li>
                        )
                    })
                }
            </ul>
            <div className="subtotal">
                <strong>{ Tr("shpCar5") }</strong>
                <div>{ list && list.length ? total && total.actuallyPaidAmount : 0}{getCurrency()}</div>
            </div>
            <Link onClick={closeModal} to={"/shopcar"}>{ Tr("shpCar6") }</Link>
            <a href="javascript:;" onClick={ goToCheckout }>{ Tr("checkout5") }</a>
            {/*<Link onClick={closeModal} to={"/checkout"}></Link>*/}
        </div>
    )
}
