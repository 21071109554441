export const actions = {
    userInfo(data){
        return {
            type: 'USERINFO',
            data: data
        }
    },
    screeInfo(data){
        return {
            type: 'RESIZE',
            data: data
        }
    },
    language(data, noRefresh){
        return {
            type: 'LANGUAGE',
            data: data,
            noRefresh
        }
    },
    languageType(data){
        return {
            type: 'LANGUAGETYPE',
            data: data
        }
    },
    categoryData(data){
        return {
            type: 'CATEGORYDATA',
            data: data
        }
    },
    shopCarList(data){
        return {
            type: 'SHOPCARLIST',
            data: data
        }
    },
    storeInfo(data){
        return {
            type: 'STOREINFO',
            data: data
        }
    },
    collectList(data){
        return {
            type: 'COLLECTLIST',
            data: data
        }
    },
    categorySelectedData(data){
        return {
            type: 'CATEGORYSELECTED',
            data: data
        }
    },
    helpCenter(data){
        return {
            type: 'HELPCENTER',
            data: data
        }
    }
};
