export default {
    aboutUs: 'About Us',
    allGoods: 'All Products',
    loadMore: 'Load More',
    reachBottom: 'No More Items',
    goodsKinds: 'Product Categories',
    name: 'Name',
    price: 'Price',
    stock: 'Stock',
    operate: 'Actions',
    edit: 'Edit',
    delete: 'Delete',
    sure: 'Confirm',
    save: 'Save',
    cancel: 'Cancel',
    time: 'Time',
    status: 'Status',
    look: 'View',
    pics: 'Pictures',
    discount: 'Discount',
    number: 'Quantity',
    total: 'Total Price',
    search: 'Search',
    loading: 'Loading',

    checkout1: "Credit Card Payment",
    checkout2: "Cash on Delivery",
    checkout3: "Offline Payment",
    checkout4: "Please select an address before placing an order",
    checkout5: "Place Order",
    checkout6: "Add New Address",
    checkout7: "Order Information",
    checkout8: "Product",
    checkout9: "Subtotal",
    checkout10: "Discount Amount",
    checkout11: "Total Amount",
    checkout12: "Order Notes",
    checkout13: "Payment Method",
    checkout14: "Confirm Order",

    collect1: "My Collection",
    collect2: "Add to Cart",
    collect3: "Remove from Collection",
    collect4: "Collect",
    collect5: "Add to My Collection",
    collect6: "Add to Cart",

    goods1: "Product Description",
    goods2: "Related Products",

    index1: "Recommended Products",
    index2: "New Arrivals",
    index3: "Discounted Products",

    personal1: "Email Address",
    personal2: "Password Change",
    personal3: "Current Password",
    personal4: "New Password",
    personal5: "Leave blank to keep unchanged",
    personal6: "Confirm New Password",
    personal7: "Save Changes",
    personal8: "Personal Center",
    personal9: "Order List",
    persona20: "Address List",
    persona21: "Account Details",
    persona22: "Log Out",

    address1: "Add New Shipping Address",
    address2: "Shipping Address",
    address3: "Contact Name",
    address4: "Contact Phone",
    address5: "Country",
    address6: "Province",
    address7: "City",
    address8: "Postal Code",
    address9: "Detailed Address",
    address10: "Set as Default Address",
    address11: "Are you sure you want to delete this address?",

    order1: "No Orders Yet",
    order2: "Browse Products",
    order3: "Order Number",
    order4: "Order",
    order5: "Order Details",
    order6: "Order Time",
    order7: "Order Status",
    order8: "Amount",
    order9: "Shipping Fee",

    shpCar1: "Shopping Cart",
    shpCar2: "Your cart is empty, go",
    shpCar3: "choose products",
    shpCar4: "",
    shpCar5: "Total",
    shpCar6: "View Cart",

    user1: "Please enter email and password",
    user2: "Invalid email format",
    user3: "Please enter account and password",
    user4: "User Login",
    user5: "Login",
    user6: "Username or Email Address",
    user7: "Password",
    user8: "Remember Me",
    user9: "Register",
    user10: "Email Address",
    user11: "Registration Successful, Please Login",
    user12: "Forgot Password",
    user14: "Return to Login",
    user15: "Forgot Password",
    user16: "Are you sure you want to log out?",

    request1: "Request Timeout",
    request2: "No Permission",
    request3: "API Error",
    request4: "You are not logged in, please log in",

    common1: "About Us",
    common2: "Contact Us",
    common3: "Last Page",
    common4: "Jump",
    common5: "Enter a valid number",
    common6: "Return",

    nav1: "Home",
    nav2: "Categories",
    nav3: "Search by Category",
    nav4: "Enter Keywords",
    nav5: "Go to Login",

    add1: "Delivery Method",
    add2: "Home Delivery",
    add3: "Pick Up",
    add4: "Out of Delivery Range",
    add5: "We have sent a link to your email! Please verify and log in",
    add7: "Configure My Cookies",
    add8: "We use cookies on our website to ensure its proper functioning, personalize its content and ads on third-party sites, and perform statistical analysis.",
    add9: "Accept",
    add10: "Help Center",
    add11: "Registration Agreement",
    add12: "Company Name",
    add13: "Phone",
    add14: "City/Country",
    add15: "Address",
    add16: "Local Delivery",
    add17: "Please choose a payment method",
    add18: "Cash on Delivery",
    add19: "Please log in to proceed",

    "ximiadd264": "Pound",
    "ximiadd265": "Yuan",
    "ximiadd266": "Euro",
    "ximiadd267": "Dollar",
    "ximiadd268": "Dinar",
    "ximiadd269": "Website technology provided by Ximi",
    "待支付": "Pending Payment",
    add20: "Prompt",
    add21: "Confirm",
    "邮箱已注册": "Email Already Registered",
    add22: "Forgot Password",
    add23: "Please enter your email, and you will receive a link to help you reset your password",
    add24: "Reset Password",
    add25: "Please enter a new password",
    add26: "Please confirm new password",
    add27: "The passwords entered do not match",
    add28: "Email has been sent to your mailbox",
    add29: "Password changed successfully, click to login",
    "已支付": "Paid",
    "已取消": "Canceled",
    "ximiadd318": "Boliviano",
    "ximiadd319": "Confirm Payment",
    "ximiadd320": "Continue Payment",
    "ximiadd323": "中文",
    "ximiadd322": "English",
    "ximiadd321": "Español",
    "ximiadd324": "1111",
    "ximiadd325": "1111",
    "ximiadd326": "1111",
    "ximiadd327": "1111",
    "ximiadd328": "1111",
    "ximiadd329": "1111",
    "ximiadd330": "1111",
}