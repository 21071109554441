/**
 * Created by chenfei on 2018/2/5.
 */

import React from 'react';
import ReactDom from 'react-dom';
import Root from './component/Root';
import {ContextProvider} from "@src/redux/reducer";


console.log(process.env.NODE_ENV);
switch (process.env.NODE_ENV) {
    case "production":
        window.serverUrl = location.protocol + "//api.ximies.com";
        break;
    default:
        // window.serverUrl = location.protocol + "//api.ximies.com";
        window.serverUrl = location.protocol + "//api-dev.ximies.com";
}

let container = document.getElementById('container');
let node = <ContextProvider><Root /></ContextProvider>;



ReactDom.render(node, container);
